import { Controller } from "@hotwired/stimulus"
// import { useTransition } from 'stimulus-use'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["container"]
  static values = {
    outsideOpenId: String
  }

  connect() {
    console.log('connect1 v:', this.outsideOpenIdValue)
    this.containerTarget.classList.add('hidden')
    // useTransition(this, { element: this.containerTarget }) // stimulus-use

    if (this.outsideOpenIdValue) {
      document.getElementById(this.outsideOpenIdValue).onclick = this.open.bind(this)
    }
  }

  open() {
    console.log('open1', this)
    // this.toggleTransition() // stimulus-use
    this.containerTarget.style.display = 'block'
  }

  hide(event) {
    console.log('hide1', event)
    // this.leave() // stimulus-use
    this.containerTarget.style.display = 'none'
  }
}
