import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  previousSibling;
  config = {
    rootMargin: '-100px',
    threshold: [0.2, 0.4, 0.6, 0.8, 1.0]
  };

  connect() {
    console.log('connected')
    window.addEventListener('DOMContentLoaded', this.listenToScroll())
  }

  listenToScroll() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id')
        const sectionsCollection = {}

        if (entry.target.getBoundingClientRect().top <= 100) {
          // chce tutaj wlaczyc tlo dla tego naglowka ktory wlasnie przekroczyl gore strony
          // musze dodac jeszcze wykrywanie scrolla zeby ten z previousiblingiem wykonywal sie tylko podczas scrolla do gory
          document.querySelector(`nav a[href="#${id}"]`).classList.add('bg-gray-50', 'text-gray-900')
          const navs = document.querySelectorAll(`nav#entry-sections a`)

          // get a collection of all headers with their corresponding paragraphs
          navs.forEach(nav => {
            const headerId = `${nav.getAttribute('href')}`
            if (headerId !== `#${id}`) {
              nav.classList.remove('bg-gray-50', 'text-gray-900')
            }
            const headerElement = document.querySelector(headerId)
            const headerParagraph = headerElement.nextElementSibling
            sectionsCollection[headerId] = headerParagraph
          })
        }

        // ponizsze jest proba podswietlania headera na podstawie paragrafu ktory nastepuje po nim podczas
        // scrollowania w gore, jednak nie dziala to podczas scrollowania w dol, do przemyslenia
        // this.previousSibling = entry.target.previousElementSibling
        // const key = this.getKeyByValue(sectionsCollection, this.previousSibling).toString()
        // const keyElement = document.querySelector(key)
        // if (entry.target.previousElementSibling === keyElement.nextElementSibling) {
        //   console.log('is intersecting')
        //   document.querySelector(`nav a[href="${key}"]`).classList.add('bg-gray-50', 'text-gray-900')
        //   document.querySelector(`nav a[href="#${id}"]`).classList.remove('bg-gray-50', 'text-gray-900')
        // }
      })
    }, this.config)

    // Track all headers that have an `id` applied
    document.querySelectorAll('h2[id], h3[id], h4[id], h5[id]').forEach((header) => {
      observer.observe(header)
    })
  }

  // getKeyByValue(object, value) {
  //   return Object.keys(object).find(key => object[key] === value)
  // }
}
