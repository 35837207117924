import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
// import Rails from "@rails/ujs"

// Connects to data-controller="markdown-editor"
export default class extends Controller {
  static targets = ["input", "editor", "preview", "show", "title"]
  connect() {
    this.previewTarget.classList.add('hidden')
  }

  insert(type) {
    const selectionStart = this.inputTarget.selectionStart

    const v = this.inputTarget.value
    const textBefore = v.substring(0, this.inputTarget.selectionStart)
    const textSelected = v.substring(this.inputTarget.selectionStart, this.inputTarget.selectionEnd)
    const textAfter = v.substring(this.inputTarget.selectionEnd, v.length)

    if (textBefore.slice(-1 * type.length) === type && textAfter.slice(0, type.length) === type) {
      this.inputTarget.value = textBefore.slice(0, -1 * type.length) + textSelected + textAfter.slice(type.length)
      this.inputTarget.selectionStart = selectionStart - type.length
    } else {
      this.inputTarget.value = textBefore + type + textSelected + type + textAfter
      this.inputTarget.selectionStart = selectionStart + type.length
    }

    this.inputTarget.selectionEnd = this.inputTarget.selectionStart + textSelected.length
    this.inputTarget.focus()
  }

  insertAtStart(type) {
    const selectionStart = this.inputTarget.selectionStart
    const selectionEnd = this.inputTarget.selectionEnd

    const v = this.inputTarget.value
    const indexBefore = v.substring(0, this.inputTarget.selectionStart).lastIndexOf('\n') + 1
    let indexAfter = v.substring(this.inputTarget.selectionEnd, v.length).indexOf('\n') + this.inputTarget.selectionEnd
    if (indexAfter === -1) {
      indexAfter = v.length
    }
    const textBefore = v.substring(0, indexBefore)
    const textSelected = v.substring(indexBefore, indexAfter)
    const textAfter = v.substring(indexAfter, v.length)
    console.log(textSelected)
    if (textSelected.indexOf(type) === 0) {
      this.inputTarget.value = textBefore + textSelected.slice(type.length) + textAfter
      this.inputTarget.selectionStart = selectionStart - type.length
      this.inputTarget.selectionEnd = selectionEnd - type.length
    } else {
      this.inputTarget.value = textBefore + type + textSelected + textAfter
      this.inputTarget.selectionStart = selectionStart + type.length
      this.inputTarget.selectionEnd = selectionEnd + type.length
    }

    this.inputTarget.focus()
  }

  insertAtEnd(type) {
    const selectionStart = this.inputTarget.selectionStart
    const selectionEnd = this.inputTarget.selectionEnd

    const v = this.inputTarget.value
    const indexBefore = 0
    const indexAfter = v.length + this.inputTarget.selectionEnd
    const textSelected = v.substring(indexBefore, indexAfter)
    if (textSelected.indexOf(type) === v.length - type.length) {
      this.inputTarget.value = textSelected.split(type)[0]
      this.inputTarget.selectionStart = selectionStart - type.length
      this.inputTarget.selectionEnd = selectionEnd - type.length
    } else {
      this.inputTarget.value = textSelected + " " + type
      this.inputTarget.selectionStart = v.length + type.length + 1
      this.inputTarget.selectionEnd = v.length + type.length + 1
    }

    this.inputTarget.focus()
  }

  insertAtCursor(type) {
    const selectionStart = this.inputTarget.selectionStart

    const v = this.inputTarget.value
    const textBefore = v.substring(0, this.inputTarget.selectionStart)
    // const textSelected = v.substring(this.inputTarget.selectionStart, this.inputTarget.selectionEnd)
    const textAfter = v.substring(this.inputTarget.selectionEnd, v.length)

    this.inputTarget.value = textBefore + type + textAfter
    this.inputTarget.selectionStart = selectionStart + type.length

    this.inputTarget.selectionEnd = this.inputTarget.selectionStart
    this.inputTarget.focus()
  }

  updateText(event) {
    if (event.code === 'Enter') {
      const v = this.inputTarget.value
      let lastLine = v.substring(0, this.inputTarget.selectionStart - 1)
      lastLine = lastLine.substring(lastLine.lastIndexOf('\n') + 1, lastLine.length)

      const matchNumbered = lastLine.match(/^(\d+)(\. .+)$/)
      const matchList = lastLine.match(/^-( .+)$/)
      if (matchNumbered && matchNumbered.length > 2) {
        const number = parseInt(matchNumbered[1]) + 1
        this.insertAtStart(number + ". ")
      } else if (matchList) {
        this.insertAtStart("- ")
      }
    }
  }

  insertTab(event) {
    if (event.code === 'Tab') {
      event.preventDefault()

      const selectionStart = this.inputTarget.selectionStart
      const selectionEnd = this.inputTarget.selectionEnd

      const v = this.inputTarget.value
      let indexBefore

      // jeśli jest jakiś tekst zaznaczony to chcemy dodać tab przed całą linijką
      if (selectionStart !== selectionEnd) {
        indexBefore = v.substring(0, this.inputTarget.selectionStart).lastIndexOf('\n') + 1
      } else {
        indexBefore = this.inputTarget.selectionStart
      }

      const textBefore = v.substring(0, indexBefore)
      const textAfter = v.substring(indexBefore, v.length)

      this.inputTarget.value = textBefore + "  " + textAfter
      this.inputTarget.selectionStart = selectionStart + 2
      this.inputTarget.selectionEnd = selectionEnd + 2

      this.inputTarget.focus()
    }
  }

  insertHeader() {
    this.insertAtStart('### ')
  }

  insertBold() {
    this.insert('**')
  }

  insertItalic() {
    this.insert('_')
  }

  insertStrikethrough() {
    this.insert('~~')
  }

  insertCode() {
    this.insert('`')
  }

  insertList() {
    this.insertAtStart('- ')
  }

  insertNumberedList() {
    this.insertAtStart('1. ')
  }

  insertImage() {
    const fileField = document.querySelector('#entry_entry_attachment')
    fileField.click()
    fileField.addEventListener('change', this.uploadFile.bind(this), false)
    // replacing filefield to remove event listeners
    fileField.replaceWith(fileField.cloneNode())
  }

  insertAttachmentImage(element) {
    const { alt, filename, title } = element.detail
    this.insertAtCursor(this.imgTemplate(alt, filename, title))
    this.element.scrollIntoView()
  }

  moveToTitle(event) {
    // przejdź do tytułu tylko z pierwszej linijki treści
    if (event.keyCode === 38 && this.inputTarget.selectionEnd === 0) {
      this.titleTarget.focus()
    }
  }

  moveToContent(event) {
    if (event.keyCode === 40 || event.code === 'Enter') {
      event.preventDefault()
      this.inputTarget.focus()
    }
  }

  imgTemplate(alt, filename, title) {
    if (alt.length === 0) {
      alt = 'opis'
    }
    if (title.length === 0) {
      title = 'tytul'
    }
    return `![${alt}](${filename} "${title}")`
  }

  uploadFile(event) {
    const fileField = document.querySelector('#entry_entry_attachment')
    const imagesField = document.querySelector('#editor_images')
    const uploadPath = fileField.dataset.directUploadUrl
    // let createAttachmentPath = fileField.dataset.createAttachmentUrl
    const fileList = event.target.files
    console.log(fileList)
    const file = fileList[fileList.length - 1]
    const upload = new DirectUpload(file, uploadPath)

    upload.create((error, blob) => {
      if (error) {
        console.log('upload error', error)
      } else {
        console.log('upload success')
        this.insertAtCursor(this.imgTemplate('opis', `${blob.filename}`, 'tytul'))
        imagesField.setAttribute("value", `${imagesField.value ? imagesField.value + ',' : ''}${blob.signed_id}`)
      }
    })
  }

  // toggleWrite() {
  //   this.editorTarget.classList.remove("hidden")
  //   this.previewTarget.classList.add("hidden")
  // }

  // togglePreview() {
  //   this.editorTarget.classList.add("hidden")
  //   this.previewTarget.classList.remove("hidden")
  // }
}
