import { Controller } from "@hotwired/stimulus"
// import { useClickOutside, useIntersection, useTransition } from 'stimulus-use'
import { useTransition } from 'stimulus-use'
// import { IntersectionController } from 'stimulus-use'
// import { useTransition } from 'stimulus-use/dist/use-transition'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["sidebar"]

  connect() {
    console.log('connect1')
    // useIntersection(this)
    // useClickOutside(this)
    // this.sidebarTarget.classList.add('hidden')
    // this.element.textContent = "Hello World from Stimulus1! Dropdown"
    const hidden = this.sidebarTarget.classList.contains('hidden')
    useTransition(this, { element: this.sidebarTarget })

    if (!hidden) {
      this.toggle()
    }
  }

  toggle() {
    console.log('toggle1')
    this.toggleTransition()
  }

  hide(event) {
    // console.log('hide1', this.element, event.target)
    // console.log('hide1', event)
    this.leave()
  }

  disconnect() {
  }

  signOut(event) {
    if (this._isTurboNativeApp) {
      event.preventDefault()
      event.stopImmediatePropagation()
      window.TurboNativeBridge.postMessage("signOut")
    }
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf("Turbo Native") !== -1
  }
}
