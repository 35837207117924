import { Controller } from "@hotwired/stimulus"
// import { useClickOutside, useIntersection, useTransition } from 'stimulus-use'
import { useTransition, useDispatch } from 'stimulus-use'
// import { IntersectionController } from 'stimulus-use'
// import { useTransition } from 'stimulus-use/dist/use-transition'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["attachment", "slide", "filename", "modal"]

  eventCreated = false
  currentImage = null
  hidden = true

  connect() {
    useDispatch(this)
    console.log('connect1')
    // useIntersection(this)
    // useClickOutside(this)
    // this.attachmentTarget.classList.add('hidden')
    // this.element.textContent = "Hello World from Stimulus1! Dropdown"
    const hidden = this.attachmentTarget.classList.contains('hidden')
    useTransition(this, { element: this.attachmentTarget })

    if (!hidden) {
      this.toggle()
    }
  }

  toggle(event) {
    this.hidden = !this.hidden
    console.log('toggle1')
    this.toggleTransition()
    // https://discuss.hotwired.dev/t/add-and-remove-eventlisteners/710
    if (this.eventCreated === false) {
      const fn = this.outsideHide.bind(this)
      this.eventCreated = fn
      console.log('eventCreated1')
      document.addEventListener('keydown', fn)
      document.addEventListener("click", fn)
    } else {
      this.removeEvent()
    }
  }

  toggle1(event) {
    var newElement, newIndex, currentIndex
    if (this.hidden) {
      this.toggle(event)
    }
    const allTargetIds = this.slideTargets.map(element => {
      return element.id
    })
    const idsLength = allTargetIds.length
    if (this.currentImage != null) {
      const oldElement = document.getElementById(this.currentImage)
      oldElement.classList.add("hidden")
      currentIndex = allTargetIds.indexOf(this.currentImage, 0)
    }
    if (event.key === 'ArrowLeft') {
      newIndex = (currentIndex <= 0 ? idsLength : currentIndex) - 1
      newElement = this.unhideElement(allTargetIds[newIndex])
    } else if (event.key === 'ArrowRight') {
      newIndex = currentIndex + 1
      newIndex = newIndex % idsLength
      newElement = this.unhideElement(allTargetIds[newIndex])
    } else {
      newElement = this.unhideElement(event.params.id)
    }
    this.currentImage = newElement.id
  }

  hide(event) {
    console.log('hide1', this.element, event.target)
    // console.log('hide1', event)
    this.leave()
    // this.removeEvent()
  }

  outsideHide(event) {
    const key = event.key
    console.log('outsideHide1', event)
    if (key === 'ArrowLeft' || key === 'ArrowRight') {
      this.toggle1(event)
    }
    if (key === 'Escape' && this.hidden === false && !this.attachmentTarget.classList.contains('hidden')) {
      console.log('hide called')
      this.toggle(event)
      // this.hidden = true
    }
  }

  removeEvent() {
    if (this.eventCreated !== false) {
      console.log('removeEvent1')
      document.removeEventListener("click", this.eventCreated)
      document.removeEventListener('keydown', this.eventCreated)
      this.eventCreated = false
    }
  }

  unhideElement(id) {
    const element = document.getElementById(id)
    element.classList.remove("hidden")
    return element
  }

  toggleModal(event) {
    const attachmentOptions = {
      title: event.currentTarget.dataset.title,
      description: event.currentTarget.dataset.description,
      filename: event.currentTarget.dataset.filename,
      attachmentId: event.currentTarget.dataset.id
    }
    const id = `modal_attachment_dialog`
    const targetModal = document.getElementById(id)
    const modalController = this.application.getControllerForElementAndIdentifier(
      targetModal,
      "modal-view"
    )
    modalController.open(attachmentOptions)
  }

  addImage(e) {
    // const element = e.currentTarget
    const params = e.params
    console.log(params)
    const title = document.querySelector(`[id='display_title_${params.signed}']`).innerText
    const description = document.querySelector(`[id='display_description_${params.signed}']`).innerText

    this.dispatch('addImage', {
      alt: description,
      filename: params.filename,
      title: title,
    })
  }

  disconnect() {
    this.removeEvent()
  }
}
