import { useTransition } from 'stimulus-use'
import DropdownController from "./dropdown_controller"

export default class extends DropdownController {
  static targets = ["placeholder", "list", "input"]

  eventCreated = false

  connect() {
    console.log('connect1')

    this.menuTarget.classList.add('hidden')
    if (this.inputTarget.value) {
      this.placeholderTarget.classList.add('hidden')
    }

    const values = this.inputTarget.value.split(' ').filter(n => n)
    this.startSelect(values)

    useTransition(this, { element: this.menuTarget })
  }

  toggleInput() {
    const hidden = this.menuTarget.classList.contains('hidden')
    if (hidden) {
      this.toggle()
    }
  }

  select(event) {
    const index = event.params.id.lastIndexOf('_')
    const prefix = this.getPrefix(event.params.id)
    const id = event.params.id.slice(index + 1)

    this.selectHelper(prefix, id)
  }

  startSelect(values) {
    const menuId = this.menuTarget.id
    const prefix = this.getPrefix(menuId)

    values.forEach(element => {
      this.selectHelper(prefix, element)
    })
  }

  selectHelper(prefix, id) {
    // elementy które odpowiednio pokazujemy / chowamy przy wybraniu danej opcji
    const tick = document.getElementById(prefix + '_tick_' + id)
    const x = document.getElementById(prefix + '_x_' + id)
    const show = document.getElementById(prefix + '_show_' + id)

    // pole formularza
    const field = document.getElementById(prefix)
    let values = field.value.split(' ')

    if (tick.classList.contains('hidden')) {
      tick.classList.remove('hidden')
      x.classList.remove('hidden')
      show.classList.remove('hidden')
      show.classList.add('flex')

      // zabezpieczenie by nie dodawać kilka razy tego samego elementu
      if (values.indexOf(id) === -1) {
        values.push(id)
      }
    } else {
      tick.classList.add('hidden')
      x.classList.add('hidden')
      show.classList.add('hidden')
      show.classList.remove('flex')
      values = values.filter(e => e !== id)
    }

    // chowanie placeholdera jeśli jest wybrana co najmniej jedna opcja
    if (values.filter(n => n).length > 0) {
      this.placeholderTarget.classList.add('hidden')
    } else {
      this.placeholderTarget.classList.remove('hidden')
    }

    field.value = values.join(' ')
    field.innerText = values
  }

  getPrefix(target) {
    const index = target.lastIndexOf('_')
    const prefix = target.slice(0, index)
    return prefix
  }

  getSuffix(target) {
    const index = target.lastIndexOf('_')
    const suffix = target.slice(index + 1)
    return suffix
  }
}
