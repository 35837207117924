import { useTransition } from 'stimulus-use'
import Rails from "@rails/ujs"
import DropdownController from "./dropdown_controller"
const debounce = require('lodash/debounce')

export default class extends DropdownController {
  static targets = ["menu", "input", "list", "searchWait", "searchResults", "help", "error", "navigation", "subContainer"]

  eventCreated = false

  connect() {
    console.log('connect1')
    this.errorTarget.classList.add('hidden')
    this.menuTarget.classList.add('hidden')

    useTransition(this, { element: this.menuTarget })

    this.updateSearchDebounce = debounce(this.updateSearchDebounce, 500).bind(this)
  }

  updateSearchInfoShow() {
    this.errorTarget.classList.add('hidden')
    if (this.hasSearchResultsTarget) {
      this.searchResultsTarget.classList.remove('hidden')
    }
    this.helpTarget.classList.add('hidden')
    this.menuTarget.classList.remove('hidden')
    this.searchWaitTarget.classList.remove('hidden')
    this.searchWaitTarget.classList.add('block')
  }

  updateSearchInfoHide() {
    this.searchWaitTarget.classList.add('hidden')
    this.searchWaitTarget.classList.remove('block')
  }

  updateSearch(event) {
    console.log(event)
    this.updateSearchInfoShow()
    this.updateSearchDebounce(event)
  }

  updateSearchDebounce(event) {
    console.log('update')
    const input = this.inputTarget.value
    const url = this.inputTarget.dataset.url
    console.log(encodeURIComponent(input))

    event.preventDefault()

    Rails.ajax({
      url: url,
      type: 'get',
      data: "q=" + encodeURIComponent(input),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (partial) => {
        console.log('update success')
        this.updateSearchInfoHide()
        // this.announceChangedSectionToScreenReader()
      },
      error: (e) => {
        console.log('error updating')
        this.errorTarget.classList.remove('hidden')
        this.updateSearchInfoHide()
      }
    })
  }
}
