import { Controller } from "@hotwired/stimulus"

// export default class extends Controller {
//   connect () {
//     this.element.textContent = "Hello World from Stimulus1!"
//   }
// }
export default class extends Controller {
  static targets = ["fields"]

  connect() {
    console.log('connect1')
  }

  setWysiwyg() {
    this.setMarkdownOptions("wysiwyg_html")
  }

  setMarkdown() {
    this.setMarkdownOptions(null)
  }

  setAutoUpdateOff() {
    this.setMarkdownOptions("auto_update_off")
  }

  setMarkdownOptions(value) {
    const fields = this.fieldsTarget
    let fieldsAsHash = JSON.parse(fields.value)

    fieldsAsHash = this.setDefaultFields(fieldsAsHash)
    if (value) {
      fieldsAsHash.default_form_fields.task.markup_lang = value
    } else {
      delete fieldsAsHash.default_form_fields.task.markup_lang
      if (Object.keys(fieldsAsHash.default_form_fields.task).length === 0) {
        delete fieldsAsHash.default_form_fields.task
      }
      if (Object.keys(fieldsAsHash.default_form_fields).length === 0) {
        delete fieldsAsHash.default_form_fields
      }
    }

    this.fieldsTarget.value = JSON.stringify(fieldsAsHash, null, 2)
  }

  setDefaultFields(fieldsAsHash) {
    if (typeof fieldsAsHash.default_form_fields === "undefined") {
      fieldsAsHash.default_form_fields = {}
    }

    if (typeof fieldsAsHash.default_form_fields.task === "undefined") {
      fieldsAsHash.default_form_fields.task = {}
    }

    if (typeof fieldsAsHash.default_form_fields.task.markup_lang === "undefined") {
      fieldsAsHash.default_form_fields.task.markup_lang = {}
    }

    return fieldsAsHash
  }
}
